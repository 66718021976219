import React, { useState } from "react";
import { observer } from "mobx-react";
import {useChatContext} from "../../services/contexts/chat-context";
import ReactDOM from "react-dom";
import styled, { ThemeProvider } from "styled-components";
import { ChatOpenMode, IAppInitParameters } from "@sparkware/uc-sdk-core";
// import { OracleChat } from "./chat.component";
import OutOfHours from "./out-of-hours.component";
import Layout from "../layout/layout.component";

import Lottie from "lottie-react";
import { colorify } from "lottie-colorify";
import spinner from "../../resources/loading-spinner-lottie.json";
import { ChatLivePersonComponent } from "./chat-live-person.component";
import { GlobalStyle } from "../../theming/global-styles";

const WidgetLoaderContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 200px;
  padding-top: 250px;
`;

export const RootComponent = observer(() => {
  const { themes, chatData, resourceService } = useChatContext().services;
  const [isLPChatVisible, setIsLPChatVisible] = useState(false);

  // const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: colorify([themes.currentTheme.colors.primary, themes.currentTheme.colors.primary], spinner),
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     }
  //   };

  const renderLoader = () => {
    return (
      <WidgetLoaderContainer>
        <Lottie
          animationData={colorify(
            [
              themes.currentTheme.colors.primary,
              themes.currentTheme.colors.primary,
            ],
            spinner
          )}
          height={120}
          width={120}
        />
      </WidgetLoaderContainer>
    );
  };

  function _renderAppContent() {
    // services.logger.info("Render app content" , {
    //     currentTheme: services.themes.currentTheme,
    //     chathours: services.chatData
    // });

    if (!themes.currentTheme) {
      return;
    }
    if (!chatData.chatHours) {
      return;
    }

    return (
      <Layout>
        {chatData.chatOpenMode == ChatOpenMode.SOF ||
        chatData?.chatHours?.isOpened
          ? _renderChat()
          : _renderOutOfHours()}
      </Layout>
    );
  }

  function _renderOutOfHours() {
    return <OutOfHours />;
  }

  function _renderChat() {
    // if (chatData.chatSettings?.enableLPchat) {
      return (
        <div>
          {/* <LivePersonChat /> */}
          <ChatLivePersonComponent isLPChatVisible={isLPChatVisible} setIsLPChatVisible={setIsLPChatVisible}/>
        </div>
      );
    // } else {
    //   return <OracleChat />;
    // }
  }

  return (
    <ThemeProvider theme={themes.currentTheme}>
      <GlobalStyle />
      {/* {chatData.isLoading ? renderLoader() : _renderAppContent()} */}
      { _renderAppContent()}
    </ThemeProvider>
  );
});
