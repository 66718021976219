import { AxiosRequestConfig } from "axios";
import { makeObservable } from "mobx";
import { observable, action } from "mobx";
import Axios from "../../utils/AxiosInstance";
import { ServiceBase } from "../service-base";
import { IServiceFactory } from "../service-factory.interface";
import { IAuthService } from "./auth.service.interface";

export class AuthService extends ServiceBase implements IAuthService {
  constructor(services: IServiceFactory) {
    super(services);
    // let _cid = this.services.chatData.playerData?.cid.toString() ?? "";
    // window.lpModel = {
    //   cid : _cid,
    //   authServiceApi: this.services.chatData.environmentSettings.authorizationServiceApiUrl
    // };

    makeObservable(this, {
      GetLivePersonAuthCode: action.bound,
    });
  }

  async GetLivePersonAuthCode(cid: string | undefined): Promise<string> {
    if (!cid) {
      throw new Error("cid not provided");
    }

    this.services.logger.info(
      "Run Get Live Person Auth Code with @parameters",
      {
        parameters: cid,
      }
    );

    const response = await Axios(this.services)
      .get(this.environmentSettings.apiUrl + `auth/get-auth-code?cid=${cid}`)
      .catch((e) => {
        console.error(e);
        this.services.logger.error("Live Person Get Auth Code Error", e)
        throw new Error(`Could not get live person auth code: ${e}`);
      });

    this.services.logger.info("Live Person Get Auth Code response", response);

    // console.log(response.data);

    return response.data;
  }

  // isAuthenticated: boolean = false;

  // public LoadChat() {
  //   loadScriptTag(
  //     LIVE_PERSON_SCRIPT_ID,
  //     this.services.resourceService.resolveResourcePath(
  //       `/${LIVE_PERSON_SCRIPT_NAME}`
  //     )
  //   )
  //     .then(() => {
  //       console.log("live person script loaded");
  //       this.initLivePerson();
  //     })
  //     .catch(() => {
  //       console.error("Live Person load failed.");
  //     });
  // }

  // initLivePerson = () => {
  //   console.log('lpTag',window.lpTag);
  //   if (!window.lpTag) {
  //     throw new Error("Error loading LP chat!");
  //   }
  //   window.lpTag.site = LIVEPERSON_ACCOUNT_ID;
  //   //Authorization

  //   if (this.isAuthenticated) {
  //     window.lpTag.identities = [];
  //     window.lpTag.identities.push(this.livePersonIdentity);
  //   }

  //   window.lpGetToken = this.lpGetToken;
  //   window.lpGetLivePersonAuthCode = this.GetLivePersonAuthCode;
  //   //
  //   window.lpTag.init(true);
  //   console.log("firing init");
  // };

  // lpGetToken(callback: any) {
  //   try {
  //     console.log(
  //     `LP asked for id_token or auth code in Code Flow for cid ${window.lpModel.cid}`
  //     );
  //     if (typeof callback == "function") {
  //       window.lpGetLivePersonAuthCode(window.lpModel.cid)
  //       .then((authCode: string) => {
  //         console.log(`callback fired with code ${authCode}`);
  //         callback(authCode);
  //       })
  //       .catch((err: any) => {
  //         console.error(`callback error with error ${err}`);
  //         callback(err, "error reason");
  //       });
  //     }
  //   } catch (error) {
  //      console.error(error);
  //   }
  //   }

  // livePersonIdentity(callback: any) {
  //   try {
  //     console.log(
  //       `identity callback fired with url ${window.lpModel.authServiceApi}`
  //     );
  //     if (callback && typeof callback == "function") {
  //       callback({
  //         iss: window.lpModel.authServiceApi,
  //         acr: "loa1",
  //         sub: parseInt(window.lpModel.cid),
  //       });
  //     }
  //   } catch (error){
  //     console.error(error);
  //   }
  // }
}
