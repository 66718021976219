import {
  ChatOpenMode,
  IAppInitParameters,
  IAppLaunchInfo,
  IApplicationContext,
  IChatInitParameters,
} from "@sparkware/uc-sdk-core";
import { action, makeObservable, observable, values } from "mobx";
import Axios from "../../utils/AxiosInstance";
import { ErrorPayload } from "../message-broker/message-broker.interface";
import {
  AnalyticsEventData,
  ChatData,
  ChatHoursSettings,
  ChatSettings,
  EnvironmentSettings,
  IChatDataService,
  PlayerData,
} from "./chat-data.service.interface";
import { ServiceBase } from "../service-base";
import { IServiceFactory } from "../service-factory.interface";
import { isKeyof, isNotSet } from "../../utils/utility";
import { ChatChannel } from "../../types/chat-models";
import { AnalyticsEventType, CHAT_APP_ID } from "../../types/global";
import { getConfig } from "../../config";
import { v4 as uuidv4 } from "uuid";
import { AxiosRequestConfig } from "axios";
import { isJSDocTypeExpression } from "typescript";
import { getErrorMessage } from "../../utils/error";
// import { FilterItem } from "../osvc/osvc.service.interface";
// import {
//   FAQ_REPORT_LIMIT,
//   DOT_COM_BRANDS,
//   OSVC_ENGLISH_CODE,
// } from "../../enums/faq-report";

// const env =
// export type ChatHoursModel = {
//     url: string,
//     chatHoursEntry: string,
//     Interface: string,
//     timezone: string
// }

export class ChatDataService extends ServiceBase implements IChatDataService {
  constructor(services: IServiceFactory) {
    super(services);
    makeObservable(this, {
      channel: observable.ref,
      chatHours: observable.ref,
      data: observable.ref,
      chatSettings: observable.ref,
      playerData: observable.ref,
      chatOpenMode: observable.ref,
      setData: action.bound,
      setChatHours: action.bound,
      setChatSettings: action.bound,
      setPlayerData: action.bound,
      setChatOpenMode: action.bound,
    });

    ////this.services.logger.debug("Chat data service started");p
  }

  chatOpenStartTime: number | null = null;
  chatOpenMode?: ChatOpenMode;
  isLPChat?: boolean;
  isPlayerAuthenticated?: boolean;
  chatInitParameters?: IAppInitParameters;
  data?: ChatData;
  applicationContext: IApplicationContext<IAppLaunchInfo> | null = null;
  channel?: ChatChannel;
  chatHours?: ChatHoursSettings;
  chatSettings?: ChatSettings;
  playerData?: PlayerData;
  analyticsEventData: AnalyticsEventData = {
    userSentMessage: false,
    userRequestAgent: false,
    userChooseOpenBanking: false,
    sequentialCorrelationID: 0,
    correlationId: `Widget_${uuidv4()}`,
  };

  get isLoading(): boolean {
    return this.chatHours == null;
  }

  setData(value: ChatData) {
    this.data = value;
  }
  setChatHours(value: ChatHoursSettings) {
    this.chatHours = value;
  }
  setChatSettings(value: ChatSettings) {
    this.chatSettings = value;
  }
  setPlayerData(value: PlayerData) {
    this.playerData = value;
  }
  setChatOpenMode(value: ChatOpenMode) {
    this.chatOpenMode = value;
  }
  set setChatInitParamters(value: IAppInitParameters) {
    this.chatInitParameters = value;
  }

  async loadAuthenticatedInitData(inputData: IAppInitParameters, isHelpCenterCall: boolean, playerData: PlayerData | undefined = undefined): Promise<void> {
    if (isNotSet(inputData)) {
      this.throwInitFailedMesage({
        errorCode: "1",
        errorDescription: "Invaild input data",
      });
    }
    this.chatOpenStartTime = performance.now();
    // I need this line after refactoring phase1 and it should be changed when finished
    this.services.applicationService.init(inputData);
    console.log("Authenticated inputData:");
    console.log(inputData);
    this.setChatInitParamters = inputData;
    this.chatOpenMode =
      inputData.applicationContext.launchInfo.appSpecificParameters?.openMode ??
      ChatOpenMode.Chat;
    this.analyticsEventData.sequentialCorrelationID =
      inputData.applicationContext.launchInfo.sequentialCorrelationID!;
    this.isLPChat =
      inputData.applicationContext.launchInfo.appSpecificParameters?.isLAChat;
    this.isPlayerAuthenticated = inputData.userInfo.userMode === "authenticated";
    this.services.logger.info("Chat data service - Load init data", {
      applicationContext: inputData.applicationContext,
      deviceInfo: inputData.deviceInfo,
      geoInfo: inputData.geoInfo,
      userInfo: inputData.userInfo,
    });

    // start the process
    if(playerData){
      this.setPlayerData(playerData);
    }
    else {
      this.setPlayerData(await this._loadPlayerData(inputData.userInfo.token!));
    }
    

    if (isNotSet(inputData.applicationContext)) {
      throw new Error(
        "Input parametres errors: application context is missing from the init parameters object"
      );
    }

    setTimeout(() => {
      this.channel = {
        brand: inputData.applicationContext.brandID,
        lang: inputData.applicationContext.languageCode,
        subBrand: inputData.applicationContext.subBrandID,
        pval: this.playerData?.clientTypeId ?? 0,
        registrationCountry: this.playerData?.registrationCountry,
      };

      this._loadChatInformationForChannel(this.channel, isHelpCenterCall);
    }, 0);
    // intit completed
  }

  public async loadUnAuthenticatedInitData(inputData: IAppInitParameters, isHelpCenterCall: boolean): Promise<void> {

    if (isNotSet(inputData)) {
      this.throwInitFailedMesage({
        errorCode: "1",
        errorDescription: "Invaild input data",
      });
    }

    this.chatOpenStartTime = performance.now();
    console.log("Authenticated inputData:");
    console.log(inputData);
    this.setChatInitParamters = inputData;
    
    this.services.applicationService.init(inputData);

    this.isPlayerAuthenticated = false;
    
    this.channel = {
      brand: inputData.applicationContext.brandID,
      lang: inputData.applicationContext.languageCode,
      subBrand: inputData.applicationContext.subBrandID,
      pval: 0,
    };
    this._loadChatInformationForChannel(this.channel, isHelpCenterCall);
  }

  private async _loadChatInformationForChannel(channel: ChatChannel, isHelpCenterCall: boolean): Promise<void> {
    this.channel &&
      this.setChatSettings(await this._loadSettings(this.channel, isHelpCenterCall));
    this.chatSettings &&
      this.setChatHours(
        await this._loadChatHours(this.channel!, this.chatSettings.timezone)
      );
  }
  // action bound should not return a promise

  private async _loadSettings(channel: ChatChannel, isHelpCenterCall: boolean): Promise<ChatSettings> {
    this.services.logger.info("Load settings start", channel);

    const response = await Axios(this.services)
      .get(this.environmentSettings.apiUrl + "settings/chat?", {
        params: channel,
      })
      .catch((e: any) => {
        //this.throwInitFailedMesage({errorCode: "1", errorDescription: 'Could not load chat settings'});
        // this.services.logger.error("Load settings error", e);
        throw new Error("Could not load chat settings");
      });

    this.services.logger.info("Load settings response", response);
    const settings = response.data.settings;

    const botSettings = Object.entries<string>(settings)
      .filter(([key]) => key.startsWith("$"))
      .reduce((prev, [key, value]) => {
        prev[key] = value;
        return prev;
      }, <{ [key: string]: string }>{});

    // if(isHelpCenterCall){
    //   this.runOsvcReport(response.data.settings, channel);
    // }
    
    return {
      chatHoursEntry: response.data.settings.chatHoursEntry,
      brandColor: response.data.settings.brandColor,
      conversationColor: response.data.settings.conversationColor,
      actionColor: response.data.settings.actionColor,
      pageTitle: response.data.settings.pageTitle,
      closedTitle: response.data.settings.closedTitle,
      closed: response.data.settings.closed,
      botAppId: response.data.botId,
      sofBotAppId: response.data.sofBotId,
      headerText: response.data.settings.headerText,
      channelType: response.data.settings.channelType,
      channelLang: response.data.settings.channelLang,
      locale: response.data.settings.locale,
      timezone: response.data.settings.timezone,
      agentChannel: response.data.agentChannelName,
      onlyAgentFlow: response.data.settings.onlyAgentFlow,
      inputPlaceholder: response.data.settings.inputPlaceholder,
      rnBrandId: response.data.settings.rnBrandId,
      rnLanguageId: response.data.settings.rnLanguageId,
      isGoodWillBonusEnabled: response.data.settings.isGoodWillBonusEnabled,
      footertext: response.data.settings.footertext,
      sendButtonIcon: response.data.settings.sendButtonIcon,
      themeName: response.data.settings.Theme,
      brandName: response.data.settings.brandName,
      interface: response.data.settings.interface,
      interfaceRoute: response.data.settings.interfaceRoute,
      phoneHours: response.data.settings.phoneHours,
      initUserHiddenMessage: response.data.settings.initUserHiddenMessage,
      rgLink: response.data.settings.RG_link,
      FAQreportId: response.data.settings.FAQreportId,
      // osvcBrandId: response.data.settings.osvcBrandId,
      // osvcSubBrandId: response.data.settings.osvcSubBrandId,
      // osvcAnswerLanguageId: response.data.settings.osvcAnswerLanguageId,
      showEmailButton: response.data.settings.showEmailButton === "1",
      showAttachmentButton: response.data.settings.showAttachmentButton === "1",
      use24hTimeFormat: response.data.settings.use24hTimeFormat === "1",
      enableLPchat: response.data.settings.enableLPchat === "1",
      engagementIDAuthenticated: response.data.settings.EngagementID_Auth,
      engagementIDUnauthenticated: response.data.settings.EngagementID_NoAuth,
      engagementIDProactive: response.data.settings.EngagementID_Proactive,
      htmlEngagementIDAuthenticated: response.data.settings.htmlEngagementID_Auth,
      htmlEngagementIDUnauthenticated: response.data.settings.htmlEngagementID_NoAuth,
      htmlEngagementIDProactive: response.data.settings.htmlEngagementID_Proactive,
      showChatButtonAuthenticated: response.data.settings.showChatButton_Auth === "1",
      showChatButtonUnauthenticated: response.data.settings.showChatButton_NoAuth === "1",
      isUSBrand: response.data.settings.isUSBrand === "True",
      dynamicSettings: botSettings,
    };
  }

  // public runOsvcReport(
  //   settings: ChatSettings | undefined,
  //   channel: ChatChannel | undefined
  // ) {
  //   if (!settings || !channel) {
  //     return;
  //   }

  //   this.services.osvcService.RunReport({
  //     id: settings.FAQreportId,
  //     limit: FAQ_REPORT_LIMIT,
  //     filters: [
  //       {
  //         name: "Brand",
  //         values: DOT_COM_BRANDS.includes(channel.brand)
  //           ? settings.osvcBrandId.toString()
  //           : settings.osvcSubBrandId.toString(),
  //       } as FilterItem,
  //       {
  //         name: "Language",
  //         values: DOT_COM_BRANDS.includes(channel.brand)
  //           ? OSVC_ENGLISH_CODE.toString()
  //           : settings.osvcAnswerLanguageId.toString(),
  //       } as FilterItem,
  //     ],
  //     interfaceUrl: settings.interfaceRoute,
  //   });
  // }

  private async _loadPlayerData(token: string): Promise<PlayerData> {
    if (isNotSet(token)) {
      this.throwInitFailedMesage({
        errorCode: "1",
        errorDescription: "Player token was not provided",
      });
    }

    this.services.logger.info("Load player data start @token", {
      token: token,
    });
    //console.log(token);
    // let headers: AxiosRequestConfig['headers'] = {};
    // headers['X-CorrelationId'] = 'test_correlation';
    const response = await Axios(this.services)
      .post(
        this.environmentSettings.apiUrl + "player/get-data",
        {
          token: token,
        } //,headers
      )
      .catch((e) => {
        //this.throwInitFailedMesage({errorCode: "1", errorDescription: 'Could not load player data'})
        // this.services.logger.error("Load player data error", e);
        throw new Error(`Could not load player data with error: ${e}`);
      });

    this.services.logger.info("Load player data response", response);

    return {
      cid: response.data.playerId,
      email: response.data.email,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      userName: response.data.userName,
      playerValue: response.data.playerValue,
      playerValueId: response.data.playerValueID,
      clientTypeId: response.data.clientTypeID,
      currentClientVersion: response.data.currentClientVersion,
      countryISO3: response.data.countryISO3,
      countryId: response.data.countryId,
      registrationCountry: response.data.registrationCountry,
      isUk: response.data.isUk,
    };
  }

  private async _loadChatHours(
    channel: ChatChannel,
    timeZone: string
  ): Promise<ChatHoursSettings> {
    this.services.logger.info("Load chat hours start", {
      channel: channel,
      timeZone: timeZone,
    });

    try {
      const res = await Axios(this.services).post(
        this.environmentSettings.apiUrl + "settings/chat-hours",
        {
          interface: {
            BrandId: channel.brand,
            SubBrandId: channel.subBrand,
            LanguageId: channel.lang,
            PlayerValue: channel.pval,
            RegistrationCountry: channel.registrationCountry,
          },
          timezone: timeZone,
        }
      );

      this.services.logger.info("Load chat hours result", res);

      return <ChatHoursSettings>{
        isOpened: res.data.isOpen,
        isDisabled: res.data.isDisabled,
        outsideOfWorkingHoursText: res.data.outsideOfWorkingHoursMessage,
        urgentMessageText: res.data.urgentMessage,
        openHours: res.data.openHours,
        openHoursTimezone: res.data.openHoursTimezone
      };
    } catch (ex) {
      throw new Error(`Could not load chat hours. ${getErrorMessage(ex)}`);
    }
    // const res = await Axios(this.services).post(this.environmentSettings.apiUrl + 'settings/chat-hours', {
    //     interface: {
    //         BrandId: channel.brand,
    //         SubBrandId: channel.subBrand,
    //         LanguageId: channel.lang,
    //         PlayerValue: channel.pval,
    //         RegistrationCountry: channel.registrationCountry
    //     },
    //     timezone: timeZone
    // }).catch((e)=>{
    //     // this.services.logger.error("Load chat hours error", e);
    //     throw new Error('Could not load chat hours');
    // });

    // this.services.logger.info("Load chat hours result", res);

    // return <ChatHoursSettings>{
    //     isOpened: res.data.isOpen,
    //     isDisabled: res.data.isDisabled,
    //     outsideOfWorkingHoursText: res.data.outsideOfWorkingHoursMessage,
    //     urgentMessageText: res.data.urgentMessage
    // };
  }

  private throwInitFailedMesage(payload: ErrorPayload) {
    this.services.messageBroker.initFailed(CHAT_APP_ID, payload);
    throw new Error(payload.errorDescription);
  }

  get environmentSettings(): EnvironmentSettings {
    // return {
    //     apiUrl: "",
    //     odaUri: "",
    //     resourcesOrigin: ""
    // };
    return getConfig();
  }
}
