import { Check } from "@sparkware/app-utils";
import {
  ActionID,
  AppIdentifiers,
  AreaType,
  TriggerType,
  IOsvcActionData
} from "@sparkware/uc-sdk-core";
import {
  IAppInitParameters,
  IAppLaunchInfo,
} from "@sparkware/uc-sdk-core/lib/apps/app-init-parameters.interface";
import {
  IChatLaunchInfo,
  IChatSpecificParams,
  IOpenEmailSpecificParameters,
} from "@sparkware/uc-sdk-core/lib/apps/chat/chat.intefaces";
import { PlayerChannel } from "@sparkware/uc-sdk-core/lib/message-broker/channels/player/player.channel";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { act } from "react-test-renderer";
import { getConfig } from "../../config";
import { ChatChannel } from "../../types/chat-models";
import Axios from "../../utils/AxiosInstance";
import { getErrorMessage } from "../../utils/error";
import { createErrorPayload, isNotSet } from "../../utils/utility";
import { ApplicationService } from "../application.service";
import { EnvironmentSettings } from "../chat-data/chat-data.service.interface";
import { ILogger } from "../logging.service";
import { ServiceBase } from "../service-base";
import { ServiceFactory } from "../service-factory";
import { IServiceFactory } from "../service-factory.interface";
import { IHelpDataService } from "./help-data.service.interface";
import { v4 as uuidv4 } from "uuid";

export interface IHelpServiceViewModel {
  isLoading: boolean;
}
export class HelpDataService
  extends ServiceBase
  implements IHelpDataService, IHelpServiceViewModel
{
  constructor(serviceFactory: IServiceFactory) {
    super(serviceFactory);

    makeObservable(this, {
      shouldBeAuthenticated: observable.ref,
      faqPageUrl: observable.ref,
      emailPageUrl: observable.ref,
      phoneNumber: observable.ref,
      hasFaq: observable.ref,
      hasEmail: observable.ref,

      // viewModel
      isLoading: observable.ref,

      init: action.bound,
    });
  }

  async openChat(): Promise<void> {
    const { applicationService, playerService } = this.services;

    const launchInfo =
      applicationService.appInitParameters?.applicationContext.launchInfo;
    const correlationId = uuidv4(); // create a new, fresh correlationId
    this.services.messageBroker.performAction<IChatSpecificParams>(
      AppIdentifiers.CRHelpCenter,
      ActionID.openChat, //change this
      {
        businessCorrelationID: launchInfo?.businessCorrelationID ?? "",
        channel: { area: AreaType.button, source: AppIdentifiers.CRHelpCenter },
        sequentialCorrelationID: ++applicationService.sequentialCorrelationID,
        trigger: TriggerType.userSelection,
        sourceAppID: AppIdentifiers.CRHelpCenter,
        sourceAppVersion: "1.0.1",
      }
    );
    console.log(`in the openChat function`)
  }

  async openFAQ(): Promise<void> {
    // Oracle example (legacy): https://888-external-dk--tst.custhelp.com/app/home/p/884
    // Zendesk example   (new): https://help.888.es/hc/es-es

    // let isFaqUrlLegacy = this.faqPageUrl?.includes("custhelp");

    // if (isFaqUrlLegacy) {
    //   await this.openOracleFAQ();
    // } else {
      await this.openZendeskFAQ();
    // }
  }

  // private async openOracleFAQ(): Promise<void> {
  //   const { applicationService, playerService } = this.services;

  //   const launchInfo =
  //     applicationService.appInitParameters?.applicationContext.launchInfo;

  //   this.services.messageBroker.performAction<IOsvcActionData>(
  //     AppIdentifiers.CRHelpCenter,
  //     ActionID.openOnlineHelp,
  //     {
  //       businessCorrelationID: launchInfo?.businessCorrelationID ?? "",
  //       channel: {
  //         area: AreaType.button,
  //         source: AppIdentifiers.CRHelpCenter,
  //       },
  //       sequentialCorrelationID: ++applicationService.sequentialCorrelationID,
  //       trigger: TriggerType.userSelection,
  //       sourceAppID: AppIdentifiers.CRHelpCenter,
  //       sourceAppVersion: "1.0.1",
  //     },
  //     {
  //       url: this.faqPageUrl ?? "",
  //       relatedAccountCid: playerService.playerData?.cid,
  //       correlationId: applicationService.appInitParameters?.correlationID,
  //       registrationCategory: 1,
  //     }
  //   );
  // }

  private async openZendeskFAQ(): Promise<void> {
    this.services.messageBroker.performAction(
      AppIdentifiers.CRHelpCenter,
      ActionID.openLink,
      undefined,
      {
        url: this.faqPageUrl ?? "",
        openExternally: true,
      }
    );
  }

  async openEmail(): Promise<void> {
    const { applicationService, playerService } = this.services;

    try {
      const launchInfo =
        applicationService.appInitParameters?.applicationContext.launchInfo;
      if (!Check.isNullOrUndefined(launchInfo?.sequentialCorrelationID)) {
        launchInfo!.sequentialCorrelationID++;
      }

      let launchInfoParam = {
        businessCorrelationID: launchInfo?.businessCorrelationID ?? "",
        channel: { area: AreaType.button, source: AppIdentifiers.CRHelpCenter },
        sequentialCorrelationID: launchInfo?.sequentialCorrelationID ?? 1,
        trigger: TriggerType.userSelection,
        sourceAppID: AppIdentifiers.CRHelpCenter,
        sourceAppVersion: "1.0.1",
      };

      let actionDataParam = {
        url: this.emailPageUrl ?? "",
        relatedAccountCid: playerService.playerData?.cid,
        correlationId: applicationService.appInitParameters?.correlationID,
        registrationCategory: 1,
      };

      this.services.logger.info("PerformAction - Open Email", {
        launchInfo: launchInfoParam,
        actionData: actionDataParam,
      });

      this.services.messageBroker.performAction<IOsvcActionData>(
        AppIdentifiers.CRHelpCenter,
        ActionID.openEmail, //change this
        launchInfoParam,
        actionDataParam
      );
    } catch (error) {
      this.services.logger.error("PerformAction - Open Email Error", error);
      throw error;
    }
  }

  //get hasEmail(): boolean {
  //    return !isNotSet(this.emailPageUrl);
  //}
  // view logic
  isLoading: boolean = true;

  // app logic
  faqPageUrl?: string | undefined;
  emailPageUrl?: string | undefined;
  phoneNumber?: string | undefined;
  hasFaq: boolean = false;
  shouldBeAuthenticated: boolean = false;
  hasEmail: boolean = false;

  get environmentSettings(): EnvironmentSettings {
    return getConfig();
  }

  async init(inputData: IAppInitParameters): Promise<void> {
    const NormPlayerValueId: number = 0;
    const {
      applicationService: appService,
      playerService,
      messageBroker,
      chatData,
    } = this.services;
    appService.isLoading = true;
    try {
      appService.init(inputData);
      const { userMode, token } = inputData.userInfo;
      const { brandID, languageCode, subBrandID } =
        inputData.applicationContext;
      this.shouldBeAuthenticated = userMode === "authenticated";

      const crChannel: ChatChannel = {
        brand: brandID,
        lang: languageCode,
        subBrand: subBrandID,
      };

      if (userMode === "authenticated") {
        if (isNotSet(token)) {
          this.throwAppError(
            "The provided token is empty for an authenticated player."
          );
          return;
        }
        const authenticationSucceeded = await playerService.authPlayer(token!);

        if (
          !authenticationSucceeded ||
          isNotSet(this.services.playerService.playerData)
        ) {
          messageBroker.initFailed(
            AppIdentifiers.CRHelpCenter,
            createErrorPayload("Authentication failed")
          );
          return;
        }
        const { clientTypeId, registrationCountry } =
          this.services.playerService.playerData!;
        crChannel.pval = clientTypeId;
        crChannel.registrationCountry = registrationCountry;
      } else {
        crChannel.pval = NormPlayerValueId;
      }
      // first load the seetings from the database

      //await chatData.loadInitData(inputData);
      await this._getHelpCenterSettings(crChannel);
      appService.isLoading = false;

      // this should be refactored
      if (userMode === "authenticated") {
        await chatData.loadAuthenticatedInitData(
          inputData,
          true,
          playerService.playerData
        );
      } else {
        await chatData.loadUnAuthenticatedInitData(inputData, true);
      }
      // everything was successful, anounce the container

      messageBroker.initSucceded(AppIdentifiers.CRHelpCenter);
    } catch (e) {
      this.logger.error(getErrorMessage(e));
      messageBroker.initFailed(
        AppIdentifiers.CRHelpCenter,
        createErrorPayload(getErrorMessage(e))
      );
    } finally {
      appService.isLoading = false;
    }
  }

  private async _getHelpCenterSettings(channel: CRChannel): Promise<void> {
    this.logger.info(
      `Load help center settings start for channel:  ${JSON.stringify(channel)}`
    );

    const response = await Axios(this.services)
      .get(this.environmentSettings.apiUrl + "settings/help-centre?", {
        params: channel,
      })
      .catch((e: any) => {
        this.logger.error(
          `Load help center settings error: ${getErrorMessage(e)}`
        );
        throw new Error("Could not load help center settings");
      });

    this.phoneNumber = response.data.phoneNumber;
    this.faqPageUrl = response.data.faqUrl;
    this.emailPageUrl = response.data.emailUrl;
    this.hasFaq = response.data.hasFaq;
    this.hasEmail = response.data.hasEmail;
    return;
  }
}

export type CRChannel = ChatChannel;
