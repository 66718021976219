import React, { useState } from 'react';
import { FC } from 'react';
import { createGlobalStyle, ServerStyleSheet } from 'styled-components';
import { useChatContext } from '../../services/contexts/chat-context';
import { AnalyticsEventType, CHAT_APP_ID, CHAT_TOPIC_HEADERCHAT_APP_ID } from '../../types/global';
import styled from 'styled-components';
// import { OracleChat } from '../chat/chat.component';
import { ClosePopup } from '../chat/close-popup.component';
import { ChatOpenMode } from '@sparkware/uc-sdk-core';
import { ChatWidgetAppController } from '../../sdk/chat-widget.controller';
import { CloseButtonSVG } from '../../resources/SVG/close-button';
import { MinimizeButtonSVG } from '../../resources/SVG/minimize-button';

const ChatContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
    background: ${(props: any) => props.theme.colors.mainWindowBackground};

    &::-webkit-scrollbar{
        width: 3px;
    }
      
    &::-webkit-scrollbar-thumb{
        background: #717171;
        border-radius: 3px;
    }
`;

const ChatMenuContainer = styled.div`
    position: relative;
    height: 50px;
    width: 100%;
    float: right;

    color: ${(props: any) => props.theme.colors.chatTitle};
    letter-spacing: 0.98px;
    font-family: ${(props: any) => props.theme.fontFamily}; !important;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    z-index: 20000;
    background-color: ${(props: any) => props.theme.colors.primaryHeader};
`;

const MinimizeStyle = createGlobalStyle<{}>`
    .minimize {
        margin-right: 25px;
    }
`;

const CloseChatMenuButton = styled.div`
    position: absolute;
    display: inline-block;
    right: 0;
    margin-right: 4.8%;
    margin-top: 6px;
    cursor: pointer;
    z-index: 20000;
`;

const MinimizeChatMenuButton = styled.div`
    position: absolute;
    display: inline-block;
    right: 0;
    margin-right: 13%;
    cursor: pointer;
    z-index: 20000;
`;

const ChatMinimizeButton = styled.img`
    width: 22px;
    vertical-align: middle;
`;

const ChatCloseButton = styled.img`
    width: 17px;
    vertical-align: middle;
`;

const Layout: FC<any> = (props: any) => {

    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [isChatClose, setIsChatClose] = useState(false);

    const services = useChatContext().services;
    const languageService = services.languageService;
    var isLookingToCloseChatWidget = false;

    const onMinimizeClick = () => {
        services.messageBroker. minimizeApp(CHAT_APP_ID);

        if (!services.chatData.analyticsEventData.userSentMessage) {
            services.logger.info("App minimized before chat action.");
        }

        services.logger.info("App minimized.");
        setTimeout(() => {
            let lines = document.getElementsByClassName(`lpc_transcript lpc_transcript_maximized ${window.lpTag.unifiedWindow?.customization?.deviceClass}`);
      
            if (lines.length > 0) {
              console.log("Inside the scrollIntoView if condition layout.component.tsx");
              const lastMessage = lines[0]?.lastChild as HTMLDivElement;
              console.log("lastMessage element in layout.component.tsx:", lastMessage);
              lastMessage?.scrollIntoView({ behavior: "smooth" }); // Add smooth scrolling
              console.log("after running scrollIntoView in layout.component.tsx");
            }
          }, 2500); // Adjust delay if needed
        
    }

    const onCloseClick = () => {
        if (services.chatData.chatOpenMode == ChatOpenMode.SOF || services.chatData.chatHours?.isOpened) {
            if(services.chatData.chatSettings?.enableLPchat) {
                closeLPChat();
            } else {
                setIsOpenPopup(true);
            }
        } else {
            setIsChatClose(true);
            services.logger.info("App closed.", {
                chatAppId: CHAT_APP_ID,
                chatOpenMode: services.chatData.chatOpenMode,
                chatIsOpened: services.chatData.chatHours?.isOpened,
            });
            services.messageBroker.appClosed(CHAT_APP_ID, services.chatData.chatInitParameters?.applicationContext.launchInfo.businessCorrelationID!);
        }
    }

    function closeLPChat() {
        services.logger.info("LP chat window close initiated");
        const lpCloseConversationButton = window.document.getElementsByClassName("lp_end-chat-action-icon")[0];
        // const lpConversationButtonWhenClosed = window.document.getElementsByClassName("lp_minimize-icon")[0];
        const lpConversationButtonWhenClosed = window.document.getElementsByClassName("lp_close-icon")[0];
        
        if (lpCloseConversationButton) {
            (lpCloseConversationButton as HTMLElement).click();
        } else {
            services.livePersonService.hideLPChat();
            setTimeout(() => {
                services.messageBroker.appClosed(CHAT_APP_ID, services.chatData.chatInitParameters?.applicationContext.launchInfo.businessCorrelationID!);
            }, 1);
        }
        
        if (window.lpTag.events.hasFired().filter((el: any) => el.appName === 'lpUnifiedWindow').pop().data?.state === 'ended') {
            if (lpConversationButtonWhenClosed) {
                (lpConversationButtonWhenClosed as HTMLElement).click();
            }
        }
    }

    const onGetPopupValue = (value: boolean) => {
        setIsOpenPopup(false);

        if (value) {
            _closeChatWidget();
        }
    }

    function _closeChatWidget() {
        if (!services.chatData.analyticsEventData.userSentMessage) {
            services.logger.info("App closed before user sent message");
        }
        services.logger.info("App closed.", {
            chatAppId: CHAT_APP_ID,
            chatOpenMode: services.chatData.chatOpenMode,
            chatIsOpened: services.chatData.chatHours?.isOpened,
        });
        services.messageBroker.appClosed(CHAT_APP_ID, services.chatData.chatInitParameters?.applicationContext.launchInfo.businessCorrelationID!);
    }

    function _renderPopup() {
        return (
            <ClosePopup onPopupSelection={onGetPopupValue} />
        )
    }

    function _renderMinimizeButton() {
        return (
            <MinimizeChatMenuButton onClick={onMinimizeClick} id="minimize-chat-button">
                
                <MinimizeButtonSVG/>
            </MinimizeChatMenuButton>
        )
    }

    return (
        <ChatContainer>
            <ChatMenuContainer id="chat_title">
                {(services.chatData.chatSettings?.enableLPchat) ? services.languageService.translate("Chat_LP_Title") : services.languageService.translate("Chat_Title")}
                <MinimizeStyle />
                <CloseChatMenuButton onClick={onCloseClick} id="close-chat-button">
                    <CloseButtonSVG/>
                </CloseChatMenuButton>
                {services.chatData.chatOpenMode == ChatOpenMode.SOF || services.chatData.chatHours?.isOpened ?
                    _renderMinimizeButton() :
                    <></>
                }
            </ChatMenuContainer>

            {
                isOpenPopup ? _renderPopup() : <></>
            }

            {props.children}
        </ChatContainer>
    )
}

export default Layout;