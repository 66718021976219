import { Check } from "@sparkware/app-utils";
import { makeObservable, observable, reaction } from "mobx";
import { IServiceBase } from "./service-base.interface";
import { IServiceFactory } from "./service-factory.interface";
import { IAssetResolver } from "../theming/architecture/asset-resolver/asset-resolver.interface";
import { IWidgetTheme } from "../theming/architecture/widget-theme/interface/widget-theme.interface";
import { ServiceBase } from "./service-base";
import { makeDefaultTheme } from "../theming/themes/default/make-default-theme";
import { makeCasinoTheme } from "../theming/themes/casino/make-casino-theme";
import { makePokerTheme } from "../theming/themes/poker/make-poker-theme";
import { makeSportTheme } from "../theming/themes/sport/make-sport-theme";
import { make777Theme } from "../theming/themes/777/make-777-theme";
import { makeWsopTheme } from "../theming/themes/wsop/make-wsop-theme";
import { makeWsopMurphyTheme } from "../theming/themes/wsop_murphy/make-wsop-murphy-theme";
import { makeSITheme } from "../theming/themes/sport-illustrated/make-si-theme";
import { makeMiCasinoTheme } from "../theming/themes/michigan-casino/make-mi-casino-theme";
import { makeMrgTheme } from "../theming/themes/mr-green/make-mrg-theme";

export interface IThemeService extends IServiceBase {
    readonly currentTheme: IWidgetTheme;

    init(brandID?: number, subBrandID?: number): void;
    // switchTheme(): void;
}

declare type themeFactoryFn = (assetResolver: IAssetResolver) => IWidgetTheme;

export class ThemeService extends ServiceBase implements IThemeService, IAssetResolver {

    currentTheme: IWidgetTheme;

    constructor(services: IServiceFactory) {
        super(services);

        const {
            applicationService
        } = this.services;

        makeObservable(this, {
            currentTheme: observable.ref,
            // switchTheme: action.bound
        });

        this.currentTheme = makeDefaultTheme(this);

        this.reactionDisposers.push(
            reaction( () => applicationService.appInitParameters,
            (initParams) => {
                if(Check.isNullOrUndefined(initParams?.applicationContext))
                    return;
                const {
                   brandID,
                   subBrandID 
                } = initParams?.applicationContext!;

                this.init(brandID, subBrandID)
            })
        )
    }

    dispose(): void {
        super.dispose()
        this.services.logger.debug("Theme disposed,  @currentTheme: ", this.currentTheme); 
    }

    init(brandID: number, subBrandID: number) {

        const chosenTheme = this._extractTheme(brandID, subBrandID);
                
        if(Check.isNull(chosenTheme)){
            this.logger.error(`Theme was not found for brand: ${brandID} and subBrand: ${subBrandID}. Switching to the default theme`)
        }
        else {
            this.currentTheme = chosenTheme(this);
        }
    }

    private _extractTheme(
        brandID: number, 
        subBrandID: number
        ): themeFactoryFn | null{
        
        const applicationThemes = {
            "casino": makeCasinoTheme,
            "poker": makePokerTheme,
            "sport": makeSportTheme,
            "casino777": make777Theme,
            "wsop": makeWsopTheme,
            "SI": makeSITheme,
            "miCasino": makeMiCasinoTheme,
            "MRG" : makeMrgTheme,
            "wsop_murphy": makeWsopMurphyTheme
        }
        
        const themesMappingArray: Record<string, themeFactoryFn> = {
            "0": applicationThemes.casino,//0	888casino			Casino
            "0|0": applicationThemes.casino,//0	888casino			Casino
            "0|111": applicationThemes.casino777,//0	888casino			Casino
            "0|112": applicationThemes.sport,//0	888casino			Sport
            "0|148": applicationThemes.poker, //poker in casino
            "1|0": applicationThemes.poker,
            "1|1": applicationThemes.poker,
            "1|114": applicationThemes.poker,
            "1|53": applicationThemes.casino,  //casino in poker
            "1|54": applicationThemes.sport,  //sport in poker
            "8|0": applicationThemes.sport,
            "8|8": applicationThemes.sport,
            "8|78": applicationThemes.casino,  //casino in sport
            "8|80": applicationThemes.poker, //poker in sport
            "31|46": applicationThemes.casino,//31	888.it	46	888Casino.it	Casino
            "31|45": applicationThemes.poker,
            "31|116": applicationThemes.sport,
            "58|81": applicationThemes.poker,
            "58|82": applicationThemes.casino,
            "58|110": applicationThemes.sport,
            "60|86" : applicationThemes.wsop_murphy, //WSOP Poker Nevada
            "71|103" : applicationThemes.wsop_murphy, //WSOP Poker NJ
            "71|104" : applicationThemes.wsop_murphy, //Rio Casino
            "75|113":applicationThemes.casino,//75	888.dk	113	888Casino.dk	Casino
            "75|114": applicationThemes.poker,
            "75|115": applicationThemes.sport, //applicationThemes.sport,   
            "76|117": applicationThemes.sport,
            "76|118": applicationThemes.poker,
            "76|119":applicationThemes.casino,//76	888g6r.ro	119	888casino.ro	Casino
            "79|125":applicationThemes.casino,//79	888.pt	125	888casino.pt	Casino
            "79|126": applicationThemes.poker,
            "81|129":applicationThemes.casino,//81	888.se	129	888casino.se	Casino
            "81|130": applicationThemes.poker,
            "81|131": applicationThemes.sport,
            "82|0":applicationThemes.wsop_murphy, // PA.WSOP.com
            "82|132":applicationThemes.wsop_murphy, // PA.WSOP.com
            "84|136": applicationThemes.sport,
            "84|137":applicationThemes.casino,//84	888.de	137	888Slots.Germany	Casino
            "84|138": applicationThemes.poker,
            "85|0":applicationThemes.wsop_murphy, // MI.WSOP.com
            "85|139":applicationThemes.wsop_murphy, // MI.WSOP.com
            "86|0":applicationThemes.SI, // SI-Colorado
            "87|141":applicationThemes.casino,//87	888.ca-on	141	888casino.ca-on	Casino
            "87|143": applicationThemes.sport,
            "86|140":applicationThemes.SI, // SI-Colorado
            "87|142": applicationThemes.poker,
            "89|0":applicationThemes.SI, // SI-VA
            "89|147":applicationThemes.SI, // SI-VA
            "90|0":applicationThemes.SI, // SI-IN
            "90|149":applicationThemes.SI, // SI-IN
            "91|0":applicationThemes.SI, // SI-MI-SPORT
            "91|150":applicationThemes.SI, // SI-MI-SPORT,
            "91|151":applicationThemes.miCasino, // SI-MI-Casino
            "92|152":applicationThemes.MRG, //MRG-SE-Casino
            "92|153":applicationThemes.MRG, //MRG-SE-Sport
            "92|155":applicationThemes.MRG, //MRG-SE-Poker,
            "93|154":applicationThemes.MRG, //MRG-DE-Casino,
            "94|156":applicationThemes.MRG, //MRG-DK-Poker,
            "94|157":applicationThemes.MRG, //MRG-DK-Casino,
            "94|158":applicationThemes.MRG, //MRG-DK-Sport,
            "95|159":applicationThemes.MRG, //MRG-MGA-Casino,
            "95|160":applicationThemes.MRG, //MRG-MGA-Sport,
            "95|161":applicationThemes.MRG, //MRG-MGA-Poker,
            "96|162":applicationThemes.casino,
            "96|163":applicationThemes.poker,
            "96|164":applicationThemes.sport,
            "96|165":applicationThemes.casino777,
        }
        // get the key as a combination of "brandID|subBrandId"
        const themeKey: string = [brandID, subBrandID].join("|")
        console.log(themeKey);
        this.logger.info(`Theme chosen for key: ${themeKey}`);
    
        return themeKey in themesMappingArray ? themesMappingArray[themeKey] : null;
    }
    //currentTheme: WidgetTheme = null;

    resolveAssetPath(relativePath: string, supportedLocalizations: string[]): string {
        return this.services.resourceService.resolveResourcePath(relativePath);
    }
}